import { useState } from 'react';
import { Auth } from '@aws-amplify/auth';
import { FILE_STATE } from '../constants/files/fileState';
import ArchivoService from '../services/file/archivo-service';

const useUpload = ({ loanId, fileId, fileName, prefix }) => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [fileData, setFileData] = useState();
  const [fileInfo, setFileInfo] = useState({
    loanId,
    fileId,
    fileName,
    prefix,
  });

  const upload = async (props = {}) => {
    const currentFileInfo = { ...fileInfo, ...props };
    setFileInfo(currentFileInfo);
    
    if (!currentFileInfo.loanId || !currentFileInfo.fileName) {
      throw new Error('Debes proporcionar la información del archivo antes de enviar');
    }

    if (!fileData) {
      throw new Error('Debes cargar un archivo antes de enviar');
    }

    try {
      const session = await Auth.currentSession();
      const token = session.getAccessToken().getJwtToken();

      const formData = new FormData();
      formData.append('file', fileData);
      formData.append('loanId', currentFileInfo.loanId);
      
      const originPath = `${currentFileInfo.prefix ? currentFileInfo.prefix + '/' : ''}${currentFileInfo.fileName}`;
      formData.append('originPath', originPath);

      const response = await fetch(process.env.REACT_APP_UPLOAD_LAMBDA_URL, {
        method: 'POST',
        headers: {
          'Authorization': token,
          'x-cognito-token': token,
        },
        body: formData,
        credentials: 'include',
      });
      

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Error al subir el archivo');
      }

      const result = await response.json();

      if (currentFileInfo.fileId) {
        await ArchivoService.update({
          archivoId: currentFileInfo.fileId,
          nombre: currentFileInfo.fileName + '.pdf',
          nombreOriginal: fileData.name,
          ruta: result.path,
          estado: FILE_STATE.UNDER_REVIEW,
        });
      }

      setUploadProgress(100);
      return result.path;

    } catch (error) {
      console.error('Upload error:', error);
      setUploadProgress(0);
      throw new Error('Error al subir el archivo');
    }
  };

  const load = (file) => {
    if (file.type !== 'application/pdf') {
      throw new Error('Solo se permiten archivos PDF');
    }

    if (file.size > 10 * 1024 * 1024) {
      throw new Error('El archivo es demasiado grande');
    }

    setFileData(file);
    setUploadProgress(0);
  };

  const clearData = () => {
    setFileData(undefined);
    setUploadProgress(0);
  };

  return { 
    upload, 
    load, 
    clearData, 
    uploadProgress,
    fileData, 
    setFileInfo 
  };
};

export default useUpload;