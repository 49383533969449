import React, { useState, useEffect, useMemo, useRef } from 'react';
import ConstantesSolicitudService from '../services/shared/constantes-solicitud-service';
import { useAuth } from '../context/auth-context';
import { useDialog } from '../context/dialog-context';
import { DIALOG } from '../constants/dialog/dialog';
import Emoji from '../components/shared/Emoji';
// import Header from "../components/Layout/Header";
import Button from '../components/DynamicForm/Button';
import FormInput from '../components/Input/FormInput';
import { useForm } from 'react-hook-form';
import { LOGIN } from '../constants/form/validations';
import useInput from '../hooks/use-input';
import { BrowserStorageCache } from '@aws-amplify/cache';

const Login = () => {
  const { guest } = ConstantesSolicitudService;
  const {
    register,
    getValues,
    watch,
    control,
    handleSubmit,
    trigger,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm();

  const dialog = useDialog();
  const {
    signIn,
    completeNewPassword,
    signUp,
    signOut,
    confirmSignUp,
    forgotPassword,
    forgotPasswordSubmit,
    resendSignUp,
    authenticated,
    authorized,
  } = useAuth();

  const [formState, setFormState] = useState(
    authenticated ? (authorized ? 'signedIn' : 'signOut') : 'signIn'
    //'maintenanceMessage'
  );
  const [formUniversities, setFormUniversities] = useState();

  const missingAttributes = useRef();

  const watchPassword = watch(['password', 'confirmPassword']);
  const watchDocument = watch(['document', 'confirmDocument']);

  const validations = useMemo(
    () => LOGIN(watchPassword[0], watchDocument[0]),
    [watchPassword[0], watchDocument[0]]
  );

  const { InputProps, setLoading } = useInput({
    errors,
    getValues,
    register,
    control,
    validations,
  });

  useEffect(() => {
    if (authenticated && !authorized) setFormState('signOut');
  }, [authenticated, authorized]);

  useEffect(() => {
    if (watchDocument[0] && watchDocument[1]) trigger('confirmDocument');
  }, [watchDocument[0]]);

  useEffect(() => {
    if (watchPassword[0] && watchPassword[1]) trigger('confirmPassword');
  }, [watchPassword[0]]);

  /* Retrieve universities at sing up */
  const fetchUniversidades = () =>
    guest
      .getUniversidades()
      .then((response) => {
        setFormUniversities(response);
        BrowserStorageCache.setItem('universities', response);
        let unis = response.filter(
          (item) => item.value !== 0 && item.value !== 4
        );
        setFormUniversities(unis);
        BrowserStorageCache.setItem('universities', unis);
      })
      .catch((error) => {
        console.error(error);
        dialog.info(DIALOG.N11, {
          devInfo: error.message || error.code || error.type,
        });
      });

  useEffect(() => {
    if (authenticated) {
      if (!formUniversities) {
        setLoading('university', true);
        fetchUniversidades();
      } else setLoading('university', false);
    }
  }, [formUniversities, authenticated]);

  /* Sign in function */
  const onSignIn = async (values, e) => {
    e.preventDefault();
    missingAttributes.current = await signIn(values).catch((error) => {
      if (error.code === 'PasswordResetRequiredException')
        setFormState('forgotPasswordSubmit');
      if (error.code === 'UserNotConfirmedException')
        setFormState('confirmSignUp');
      console.error(error);
      if (
        error.code === 'NotAuthorizedException' &&
        error.message ===
          'Temporary password has expired and must be reset by an administrator.'
      )
        error.code = 'TemporaryPasswordExpiredException';
      if (DIALOG[error.code]) dialog.info(DIALOG[error.code]);
      else
        dialog.info(DIALOG.N11, {
          devInfo: error.message || error.code || error.type,
        });
    });
    if (missingAttributes.current) setFormState('completeNewPassword');
  };

  /* Complete new password function */
  const onCompleteNewPassword = (values, e) => {
    e.preventDefault();
    return completeNewPassword({
      ...values,
      phone: values.phone ? `+57${values.phone}` : undefined,
    })
      .then(() => {
        setValue('password');
        setFormState('signIn');
      })
      .catch((error) => {
        console.error(error);
        if (DIALOG[error.code]) dialog.info(DIALOG[error.code]);
        else
          dialog.info(DIALOG.N11, {
            devInfo: error.message || error.code || error.type,
          });
      });
  };

  /* Sign up function */
  const onSignUp = (values, e) => {
    e.preventDefault();
    return signUp({ ...values, phone: `+57${values.phone}` })
      .then(() => setFormState('confirmSignUp'))
      .catch((error) => {
        console.error(error);
        const errorCode =
          error.code === 'UserLambdaValidationException'
            ? error.message.replace(/\./g, '').split(' ').pop()
            : error.code;
        if (DIALOG[errorCode]) dialog.info(DIALOG[errorCode]);
        else
          dialog.info(DIALOG.N11, {
            devInfo: error.message || error.code || error.type,
          });
      });
  };

  /* Resend sign up function */
  const onSignOut = () =>
    signOut()
      .then(() => setFormState('signIn'))
      .catch((error) => {
        console.error(error);
        if (DIALOG[error.code]) dialog.info(DIALOG[error.code]);
        else
          dialog.info(DIALOG.N11, {
            devInfo: error.message || error.code || error.type,
          });
      });

  /* Confirm sign up function for MFA */
  const onConfirmSignUp = (values, e) => {
    e.preventDefault();
    return confirmSignUp(values)
      .then(() => setFormState('signIn'))
      .catch((error) => {
        console.error(error);
        if (DIALOG[error.code]) dialog.info(DIALOG[error.code]);
        else
          dialog.info(DIALOG.N11, {
            devInfo: error.message || error.code || error.type,
          });
      });
  };

  /* Forgot password function */
  const onForgotPassword = (values, e) => {
    e.preventDefault();
    return forgotPassword(values)
      .then(() => {
        setValue('password');
        setValue('confirmPassword');
        setFormState('forgotPasswordSubmit');
      })
      .catch((error) => {
        console.error(error);
        if (DIALOG[error.code]) dialog.info(DIALOG[error.code]);
        else
          dialog.info(DIALOG.N11, {
            devInfo: error.message || error.code || error.type,
          });
      });
  };

  /* Forgot password submit function */
  const onForgotPasswordSubmit = (values, e) => {
    e.preventDefault();
    return forgotPasswordSubmit(values)
      .then(() => setFormState('signIn'))
      .catch((error) => {
        console.error(error);
        if (DIALOG[error.code]) dialog.info(DIALOG[error.code]);
        else
          dialog.info(DIALOG.N11, {
            devInfo: error.message || error.code || error.type,
          });
      });
  };

  /* Resend sign up function */
  const onResendSignUp = () =>
    resendSignUp(getValues())
      .then(() => dialog.info('Tu código de verificación ha sido reenviado'))
      .catch((error) => {
        console.error(error);
        if (DIALOG[error.code]) dialog.info(DIALOG[error.code]);
        else
          dialog.info(DIALOG.N11, {
            devInfo: error.message || error.code || error.type,
          });
      });

  /* In the UI of the app, render forms based on form state */
  /* If the form state is "signUp", show the sign up form */
  const renderSignUp = (
    <form onSubmit={handleSubmit(onSignUp)} key="signUp">
      <div className="col-span-1 grid gap-4 gap-y-8 md:grid-cols-2">
        <h5 className="col-span-1 text-xl font-bold md:col-span-2">
          Crea una cuenta nueva
        </h5>
        <FormInput
          {...InputProps('name')}
          placeholder="Nombres completos"
          type="text"
        />
        <FormInput
          {...InputProps('lastName')}
          placeholder="Apellidos completos"
          type="text"
        />
        <FormInput
          {...InputProps('email')}
          placeholder="Correo electrónico"
          type="email"
          className="col-span-1 md:col-span-2"
        />
        <FormInput
          {...InputProps('document')}
          placeholder="No. Documento"
          type="document"
        />
        <FormInput
          {...InputProps('confirmDocument')}
          placeholder="Confirmar No. Documento"
          type="document"
          preventPaste={true}
        />
        <FormInput
          {...InputProps('phone')}
          placeholder="Celular"
          type="cell-phone"
          className="col-span-1 md:col-span-2"
        />
        {/* <FormInput
          {...InputProps('university')}
          placeholder="Universidad"
          type="select"
          options={formUniversities}
          className="col-span-1 md:col-span-2"
        /> */}
        <FormInput
          {...InputProps('password')}
          placeholder="Contraseña"
          type="password"
        />
        <FormInput
          {...InputProps('confirmPassword')}
          placeholder="Confirmar contraseña"
          type="password"
          preventPaste={true}
        />
      </div>
      <div>
        <div className="mb-16 mt-2 grid grid-cols-1 gap-4 opacity-75 md:grid-cols-2">
          <div className="px-4 text-xs">
            <span>Tu contraseña debe contener:</span>
            <ul className="list-inside list-disc">
              <li>Al menos 8 caracteres</li>
              <li>Al menos 1 número</li>
              <li>Al menos 1 carácter especial</li>
              <li>Al menos 1 letra mayúscula</li>
              <li>Al menos 1 letra minúscula</li>
            </ul>
          </div>
        </div>
        <div className="grid grid-cols-1 items-center gap-4 md:grid-cols-2">
          <div className="text-center">
            <span className="inline">¿Tienes una cuenta? </span>
            <span
              className="inline cursor-pointer text-custom-purple hover:underline dark:text-custom-green"
              onClick={() => setFormState('signIn')}
            >
              Inicia sesión
            </span>
          </div>
          <Button type="submit" loading={isSubmitting}>
            Registrar
          </Button>
        </div>
      </div>
    </form>
  );

  /* If the form state is "confirmSignUp", show the confirm sign up form */
  const renderConfirmSignUp = (
    <form onSubmit={handleSubmit(onConfirmSignUp)} key="confirmSignUp">
      <div className="flex flex-col space-y-8">
        <h5 className="text-xl font-bold">Confirma tu correo electrónico</h5>
        <FormInput
          {...InputProps('email')}
          placeholder="Correo"
          type="email"
          disabled={true}
        />
        <FormInput
          {...InputProps('code')}
          placeholder="Código de verificación"
          type="number"
        />
      </div>
      <div>
        <div className="mb-20 mt-2 text-xs">
          <span>¿Perdiste tu código? </span>
          <span
            className="inline cursor-pointer text-custom-purple hover:underline dark:text-custom-green"
            onClick={onResendSignUp}
          >
            Reenviar codigo
          </span>
        </div>
        <div className="grid grid-cols-1 items-center gap-4 md:grid-cols-2">
          <div className="text-center">
            <span className="inline">Regresa a </span>
            <span
              className="inline cursor-pointer text-custom-purple hover:underline dark:text-custom-green"
              onClick={() => setFormState('signUp')}
            >
              Regístrate
            </span>
          </div>
          <Button type="submit" loading={isSubmitting}>
            Confirmar
          </Button>
        </div>
      </div>
    </form>
  );

  /* If the form state is "signIn", show the sign in form */
  const renderSignIn = (
    <form onSubmit={handleSubmit(onSignIn)} key="signIn">
      <div className="flex flex-col space-y-8">
        <h5 className="text-xl font-bold">Ingresa a tu cuenta</h5>
        <FormInput {...InputProps('email')} placeholder="Correo" type="email" />
        <FormInput
          {...InputProps('password')}
          placeholder="Contraseña"
          type="password"
        />
      </div>
      <div>
        <div className="mb-20 mt-2 text-xs">
          <span>¿Olvidaste tu contraseña? </span>
          <span
            className="inline cursor-pointer text-custom-purple hover:underline dark:text-custom-green"
            onClick={() => setFormState('forgotPassword')}
          >
            Restablece tu contraseña
          </span>
        </div>
        <div className="grid grid-cols-1 items-center gap-4 md:grid-cols-2">
          <div className="text-center">
            <span className="inline">¿Eres nuevo? </span>
            <span
              className="inline cursor-pointer text-custom-purple hover:underline dark:text-custom-green"
              onClick={() => setFormState('signUp')}
            >
              Regístrate
            </span>
          </div>
          <Button type="submit" loading={isSubmitting}>
            Ingresar
          </Button>
        </div>
      </div>
    </form>
  );

  /* If the form state is "completeNewPassword", show the sign in form */
  const renderCompleteNewPassword = (
    <form
      onSubmit={handleSubmit(onCompleteNewPassword)}
      key="completeNewPassword"
    >
      <div className="col-span-1 grid gap-4 gap-y-8 md:grid-cols-2">
        <h5 className="col-span-1 text-xl font-bold md:col-span-2">
          Completa tu registro
        </h5>
        <FormInput
          {...InputProps('email')}
          placeholder="Correo electrónico"
          type="email"
          disabled={true}
          className="col-span-1 md:col-span-2"
        />
        {missingAttributes.current?.name && (
          <FormInput
            {...InputProps('name')}
            placeholder="Nombres completos"
            type="text"
          />
        )}
        {missingAttributes.current?.lastName && (
          <FormInput
            {...InputProps('lastName')}
            placeholder="Apellidos completos"
            type="text"
          />
        )}
        {missingAttributes.current?.document && (
          <>
            <FormInput
              {...InputProps('document')}
              placeholder="No. Documento"
              type="document"
            />
            <FormInput
              {...InputProps('confirmDocument')}
              placeholder="Confirmar No. Documento"
              type="document"
              preventPaste={true}
            />
          </>
        )}
        {missingAttributes.current?.phone && (
          <FormInput
            {...InputProps('phone')}
            placeholder="Celular"
            type="cell-phone"
            className="col-span-1 md:col-span-2"
          />
        )}
        <FormInput
          {...InputProps('newPassword')}
          placeholder="Nueva contraseña"
          type="password"
        />
        <FormInput
          {...InputProps('confirmNewPassword')}
          placeholder="Confirmar nueva contraseña"
          type="password"
          preventPaste={true}
        />
      </div>
      <div className="mb-16 mt-2 grid grid-cols-1 gap-4 opacity-75 md:grid-cols-2">
        <div className="px-4 text-xs">
          <span>Tu contraseña debe contener:</span>
          <ul className="list-inside list-disc">
            <li>Al menos 8 caracteres</li>
            <li>Al menos 1 número</li>
            <li>Al menos 1 carácter especial</li>
            <li>Al menos 1 letra mayúscula</li>
            <li>Al menos 1 letra minúscula</li>
          </ul>
        </div>
      </div>
      <div className="grid grid-cols-1 items-center gap-4 md:grid-cols-2">
        <div className="text-center">
          <span className="inline">Regresa a </span>
          <span
            className="inline cursor-pointer text-custom-purple hover:underline dark:text-custom-green"
            onClick={() => setFormState('signIn')}
          >
            Inicia sesión
          </span>
        </div>
        <Button type="submit" loading={isSubmitting}>
          Confirmar
        </Button>
      </div>
    </form>
  );

  /* If the form state is "forgotPassword", show the forgot password form  */
  const renderForgotPassword = (
    <form onSubmit={handleSubmit(onForgotPassword)} key="forgotPassword">
      <div className="flex flex-col space-y-8">
        <h5 className="text-xl font-bold">Restablece tu contraseña</h5>
        <FormInput {...InputProps('email')} placeholder="Correo" type="email" />
      </div>
      <div className="mt-20 grid grid-cols-1 items-center gap-4 md:grid-cols-2">
        <div className="text-center">
          <span className="inline">Regresa a </span>
          <span
            className="inline cursor-pointer text-custom-purple hover:underline dark:text-custom-green"
            onClick={() => setFormState('signIn')}
          >
            Inicia sesión
          </span>
        </div>
        <Button type="submit" loading={isSubmitting}>
          Confirmar
        </Button>
      </div>
    </form>
  );

  /* If the form state is "forgotPasswordSubmit", show the forgot password submit form */
  const renderForgotPasswordSubmit = (
    <form
      onSubmit={handleSubmit(onForgotPasswordSubmit)}
      key="forgotPasswordSubmit"
    >
      <div className="col-span-1 grid gap-4 gap-y-8 md:grid-cols-2">
        <h5 className="col-span-1 text-xl font-bold md:col-span-2">
          Restablece tu contraseña
        </h5>
        <FormInput
          {...InputProps('email')}
          placeholder="Correo"
          type="email"
          disabled={true}
          className="col-span-1 md:col-span-2"
        />
        <FormInput
          {...InputProps('code')}
          placeholder="Código de verificación"
          type="number"
          className="col-span-1 md:col-span-2"
        />
        <FormInput
          {...InputProps('password')}
          placeholder="Nueva Contraseña"
          type="password"
        />
        <FormInput
          {...InputProps('confirmPassword')}
          placeholder="Confirmar contraseña"
          type="password"
        />
      </div>

      <div>
        <div className="mb-16 mt-2 grid grid-cols-1 gap-4 opacity-75 md:grid-cols-2">
          <div className="px-4 text-xs">
            <span>Tu contraseña debe contener:</span>
            <ul className="list-inside list-disc">
              <li>Al menos 8 caracteres</li>
              <li>Al menos 1 número</li>
              <li>Al menos 1 carácter especial</li>
              <li>Al menos 1 letra mayúscula</li>
              <li>Al menos 1 letra minúscula</li>
            </ul>
          </div>
        </div>
        <div className="grid grid-cols-1 items-center gap-4 md:grid-cols-2">
          <div className="text-center">
            <span className="inline">Regresa a </span>
            <span
              className="inline cursor-pointer text-custom-purple hover:underline dark:text-custom-green"
              onClick={() => setFormState('signIn')}
            >
              Inicia sesión
            </span>
          </div>
          <Button type="submit" loading={isSubmitting}>
            Confirmar
          </Button>
        </div>
      </div>
    </form>
  );

  /* If the form state is "signOut", show the sign out form */
  const renderSignOut = (
    <form
      className="flex flex-col space-y-8"
      onSubmit={handleSubmit(onSignOut)}
      key="signOut"
    >
      <p className="text-center">
        No cuentas con la autorización para ingresar, por favor comunícate con
        nosotros al{' '}
        <a
          href="https://api.whatsapp.com/send?phone=573225123511"
          target="_blank"
          rel="noreferrer"
          className="text-custom-purple hover:underline dark:text-custom-green"
        >
          WhatsApp Estrella 322 512 2322
        </a>{' '}
        para que podamos ayudarte.
      </p>
      <div className="flex justify-center">
        <Button type="submit" loading={isSubmitting}>
          Cerrar sesión
        </Button>
      </div>
    </form>
  );

  const renderMaintenanceMessage = (
    <p className="text-center">
      Nuestro sistema se encuentra en un proceso de actualización que requiere una breve pausa en la disponibilidad de los servicios. 
      Proyectamos estar de vuelta con ustedes el 31 de enero. Estamos contactando directamente a nuestros estudiantes con procesos en curso y coordinando con su Universidad.
      #EstamosJuntosEnEsto y agradecemos su comprensión y confianza. <br />
      <br /> Atentamente, <br />
      Equipo Estrella
    </p>
  );

  const body = {
    signIn: renderSignIn,
    signUp: renderSignUp,
    signOut: renderSignOut,
    confirmSignUp: renderConfirmSignUp,
    forgotPassword: renderForgotPassword,
    forgotPasswordSubmit: renderForgotPasswordSubmit,
    completeNewPassword: renderCompleteNewPassword,
    maintenanceMessage: renderMaintenanceMessage,
  };

  return (
    <div>
      <div className="mx-auto max-w-screen-sm space-y-16 rounded-3xl p-4 md:p-16">
        <h4 className="text-center text-4xl font-bold text-light dark:text-dark">
          ¡Hola <Emoji symbol="waving-hand" className="inline h-9"></Emoji>
          <Emoji
            symbol="smiling-face-with-smiling-eyes"
            className="inline h-9"
          ></Emoji>
          !
        </h4>
        {body[formState]}
      </div>
    </div>
  );
};

export default Login;
