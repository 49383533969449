import { createAsyncThunk } from '@reduxjs/toolkit';
import { Cache } from 'aws-amplify';
import CreditoService from '../../services/credit/credito-service';

export const fetchCredit = createAsyncThunk(
  'credit/fetchCredit',
  async (loanId, thunkAPI) => {
    try {
      const cachedCredit = Cache.getItem('requestCredit');
      
      if (cachedCredit && cachedCredit.loanId === loanId) {
        return cachedCredit;
      }

      const response = await CreditoService.get(loanId);

      Cache.setItem('requestCredit', response);
      Cache.setItem('cacheCredit', response); 

      return response;  
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);