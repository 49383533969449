import React, { useState, useEffect } from 'react';
import Login from './Login';
import Home from './Home';
import FinancingRequest from './FinancingRequest';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
  Outlet,
} from 'react-router-dom';
import { useAuth } from '../context/auth-context';
import Loading from '../components/shared/Loading';
import RequestForm from './FinancingRequest/RequestForm';
import Identity from './FinancingRequest/Identity';
import Legalization from './FinancingRequest/Legalization';
import Layout from './Layout';
import Simulator from './Simulator';
import { useStep } from '../context/step-context';
import { STEP_STATE } from '../constants/step/step';
import GuestLayout from './GuestLayout';
import { useNavbar } from '../context/navbar-context';
import UniversityForm from './UniversityForm';
import FormConfirm from './UniversityForm/FormConfirm';
import LandingPayu from './LandingPayu';

import { DIALOG } from '../constants/dialog/dialog';
import EtapaService from '../services/stage/etapa-service';
import { useDialog } from '../context/dialog-context';

// TODO: uncomment this when the financial statement feature is enabled
import FinancialSummary from './FinancialSummary';
import Autorizacion from './FinancingRequest/RequestForm/Autorizacion';
import ChangeLine from './ChangeLine';
import ChangeLineUtb from './ChangeLineUtb';
import ChangeCodeudores from './ChangeCodeudores';
import ChangeLineUsb from './ChangeLineUsb';
import ChangeLineCUC from './ChangeLineCuc';
import ChangeLineUPBBur from './ChangeLineUpbBur';
import ChangeLineUPBMed from './ChangeLineUpbMed';
import ChangeLineUPBMon from './ChangeLineUpbMon';
import ChangeLineUPBPal from './ChangeLineUpbPal';
import AndinaVariant from './AssignAndinaVariant';
import PageRejeds from './PageRejed';
import ChangeLineFAAPer from './ChangeLineFaaPer';
import ChangeLineFAAVal from './ChangeLineFaaVal';
import ChangeLineFAABog from './ChangeLineFaaBog';
let sumStep = 0;
function AppRouter() {
  const [loading, setLoading] = useState(true);
  const { authorized } = useAuth();
  const [step, setStep] = useState({});
  const dialog = useDialog();
  const { user } = useAuth();
  const stagesArray = 'stages';
  const {
    step: { stages },
  } = useStep();

  useEffect(() => {
    if (loading && (authorized === false || (authorized && stages)))
      setLoading(false);
  }, [authorized, stages]);

  useEffect(() => {
    if (user) fetchStep();
    else setStep({});
  }, [user]);

  function RequireAuth({ children }) {
    const location = useLocation();
    if (!authorized && location.pathname !== '/login')
      return <Navigate to="/login" state={{ from: location }} replace />;
    if (authorized && location.pathname === '/login')
      return <Navigate to="/" state={{ from: location }} replace />;
    return children;
  }

  function HideNav() {
    const { closeSidebar, openSidebar } = useNavbar();
    useEffect(() => {
      closeSidebar();
      return openSidebar;
    }, []);
    return <Outlet />;
  }

  const fetchStep = () =>
    EtapaService.get({ usuarioId: user.sub })
      .then((response) => {
        setStep(response);
        return response;
      })
      .catch((error) => {
        console.error(error);
        dialog.info(DIALOG.N11, {
          devInfo: error.message || error.code || error.type,
        });
      });

  function StepRoute({ stepNum }) {
    const location = useLocation();
    const stepNew = step?.[stagesArray]?.[1]?.estado;
    let state = stages?.[stepNum]?.estado;
    if (stepNum === 1) {
      if (stepNew !== state) {
        state = stepNew;
      } else {
        if (stepNew === 1 && sumStep <= 1) {
          useEffect(() => {
            if (user) {
              fetchStep();
              sumStep += 1;
            } else {
              setStep({});
            }
          }, [user]);
        } else {
          setTimeout(() => {
            sumStep = 0;
          }, 50000);
        }
      }
    }
    if (
      state === STEP_STATE.ENABLED ||
      state === STEP_STATE.PENDING ||
      state === STEP_STATE.ZAPSIGN ||
      state === STEP_STATE.FAILED
    )
      switch (stepNum) {
        case 1:
          return state === STEP_STATE.ENABLED ? (
            <RequestForm />
          ) : (
            <Autorizacion />
          );
        case 2:
          return <Identity />;
        case 3:
          return <Legalization />;
      }
    return (
      <Navigate
        to="/solicitar-financiacion"
        state={{ from: location }}
        replace
      />
    );
  }

  return loading ? (
    <Loading fullScreen={true} />
  ) : (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <RequireAuth>
              <Layout />
            </RequireAuth>
          }
        >
          <Route index element={<Navigate to="inicio" />} />
          <Route path="inicio" element={<Home />} />

          {/* TODO: uncomment this when the financial statement feature is enabled */}
          {<Route path="mi-financiacion" element={<FinancialSummary />} />}

          <Route path="solicitar-financiacion" element={<HideNav />}>
            <Route index element={<FinancingRequest />} />
            <Route path="registro">
              <Route index element={<StepRoute stepNum={1} />} />
              <Route path="linea-pro">
                <Route index element={<UniversityForm line={2} />} />
                <Route path="confirmacion" element={<FormConfirm />} />
              </Route>
              <Route path="linea-heroica">
                <Route index element={<UniversityForm line={4} />} />
                <Route path="confirmacion" element={<FormConfirm />} />
              </Route>
              <Route path="linea-andina">
                <Route index element={<UniversityForm line={9} />} />
                <Route path="confirmacion" element={<FormConfirm />} />
              </Route>
              <Route path="linea-chicamocha">
                <Route index element={<UniversityForm line={12} />} />
                <Route path="confirmacion" element={<FormConfirm />} />
              </Route>
              <Route path="linea-nova">
                <Route index element={<UniversityForm line={14} />} />
                <Route path="confirmacion" element={<FormConfirm />} />
              </Route>

              
            </Route>
            <Route path="verificacion" element={<StepRoute stepNum={2} />} />
            <Route path="legalizacion" element={<StepRoute stepNum={3} />} />
          </Route>
        </Route>
        <Route path="/" element={<GuestLayout />}>
          <Route
            path="login"
            element={
              <RequireAuth>
                <Login />
              </RequireAuth>
            }
          />
          <Route path="simulador">
            <Route index element={<Simulator />} />
            <Route path="usb" element={<Simulator universityId={'1'} />} />
            <Route
              path="externado"
              element={<Simulator universityId={'2'} />}
            />
            <Route path="utb" element={<Simulator universityId={'3'} />} />
            <Route path="tech" element={<Simulator universityId={'4'} />} />
            <Route path="cuc/barranquilla" element={<Simulator universityId={'5'} />} />
            <Route path="upb/bucaramanga" element={<Simulator universityId={'6'} />} />
            <Route path="upb/medellin" element={<Simulator universityId={'7'} />} />
            <Route path="upb/monteria" element={<Simulator universityId={'8'} />} />
            <Route path="upb/palmira" element={<Simulator universityId={'9'} />} />
            <Route path="faa/bogota" element={<Simulator universityId={'10'} />} />
            <Route path="faa/valledupar" element={<Simulator universityId={'11'} />} />
            <Route path="faa/pereira" element={<Simulator universityId={'12'} />} />
          </Route>
          <Route path="confirmacion-pago">
            <Route index element={<LandingPayu />} />
          </Route>
          <Route
            path="change-line"
            element={<ChangeLine universityId={'2'} />}
          ></Route>
          <Route
            path="change-line-utb"
            element={<ChangeLineUtb universityId={'3'} />}
          ></Route>
          <Route
            path="change-codeudores"
            element={<ChangeCodeudores universityId={'3'} />}
          ></Route>
          <Route
            path="change-line-usb"
            element={<ChangeLineUsb universityId={'1'} />}
          ></Route>
          <Route
            path="change-line-upb-bur"
            element={<ChangeLineUPBBur universityId={'6'} />}
          ></Route>
          <Route
            path="change-line-upb-med"
            element={<ChangeLineUPBMed universityId={'7'} />}
          ></Route>
          <Route
            path="change-line-upb-mon"
            element={<ChangeLineUPBMon universityId={'8'} />}
          ></Route>
          <Route
            path="change-line-upb-pal"
            element={<ChangeLineUPBPal universityId={'9'} />}
          ></Route>
          <Route
            path="change-line-cuc"
            element={<ChangeLineCUC universityId={'5'} />}
          ></Route>
          <Route
            path="change-line-faa-bog"
            element={<ChangeLineFAABog universityId={'10'} />}
          ></Route>
          <Route
            path="change-line-faa-val"
            element={<ChangeLineFAAVal universityId={'11'} />}
          ></Route>
          <Route
            path="change-line-faa-per"
            element={<ChangeLineFAAPer universityId={'12'} />}
          ></Route>
          <Route 
            path="andina-selection"
            element={<AndinaVariant universityId={'2'}/>} 
          ></Route>
          <Route
            path="page-rejed"
            element={<PageRejeds universityId={'3'} />}
          ></Route>
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}

export default AppRouter;
