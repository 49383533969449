import React, { useState, useEffect, useMemo } from 'react';
import Slider from 'rc-slider';
import '../styles/sliderBar.css';
import { formatCurrency } from '../utils/format';
import { useDialog } from '../context/dialog-context';
import { DIALOG } from '../constants/dialog/dialog';
import { Controller, useFormContext } from 'react-hook-form';
// import Emoji from '../components/shared/Emoji';
import LoanSimulationService from '../services/process/loan-simulation-service';
import { useInstallment } from '../context/installment-context';
import { Cache } from 'aws-amplify';
// import useVideo from '../hooks/use-video';

const useTermSlider = (fetchedConsultCreditRenovation = null) => {
  const { watch } = useFormContext();
  const watchLinea = fetchedConsultCreditRenovation ? String(fetchedConsultCreditRenovation.linea.id) : watch('linea');
  const installmentContext = useInstallment();
  const [installments, setInstallments] = installmentContext
    ? [installmentContext.installments, installmentContext.setInstallments]
    : useState([]);
  const dialog = useDialog();
  // const { showVideo } = useVideo();

  const fetchCreditTaxes = (props) =>
    LoanSimulationService.get(props)
      .then((response) => {
        setInstallments(response.data);
        if ( response && String(watchLinea) === '9') {
          Cache.setItem('plazo_andina', response.data[response.data.length-1]?.term);
        }
      })
      .catch((error) => {
        console.error(error);
        dialog.info(DIALOG.N11, {
          devInfo: error.message || error.code || error.type,
        });
      });

  const fetchCreditTaxesCreate = (props) =>
    LoanSimulationService.create(props)
      .then((response) => setInstallments(response.data))
      .catch((error) => {
        console.error(error);
        dialog.info(DIALOG.ErrorSimulacionS, {
          devInfo: error.message || error.code || error.type,
        });
      });

  const TermSlider = ({
    classNameBar,
    classNameResult,
    name,
    value,
    control,
    validation,
    errors,
  }) => {
    if (Number(watchLinea) === 3) {
      const key = value <= 6 ? value - 1 : 5;
      value = installments[key]?.term;
    } else {
      let search = false;
      let key = 0;
      installments?.forEach(function (element, index) {
        if (element.term === value) {
          search = true;
          key = index;
        }
      });
      search ? (value = installments[key]?.term) : (value = 6);
    }
    const [term, setTerms] = useState(value);
    const [installment, setInstallment] = useState(0);
    const findInstallment = (term) =>
      installments?.find((e) => parseInt(e.term) === parseInt(term))
        ?.installment;

    useEffect(
      () => setInstallment(findInstallment(term)),
      [installments, term]
    );

    const [min, max] = useMemo(
      () =>
        installments.length > 0
          ? [installments[0].term, installments[installments.length - 1].term]
          : [],
      [installments]
    );

    validation = validation
      ? {
          ...validation,
          validate: {
            ...validation.validate,
            notValid: (value) => findInstallment(value) > 0 || 'Invalido',
          },
        }
      : {};

    return (
      <>
        <div className={classNameBar}>
          <div className="flex justify-between">
            <p>{min || '?'} meses</p>
            <p>
              <b>Mueve la barra</b>
            </p>
            <p>{max || '?'} meses</p>
          </div>
          <Controller
            control={control}
            name={name}
            rules={validation}
            render={({ field: { onChange, onBlur, value } }) => (
              <Slider
                className="my-4 h-3 w-full rounded-full bg-custom-green"
                range
                min={min || 0}
                max={max || 0}
                onChange={(newValue) => {
                  onChange(newValue[0]);
                  setTerms(newValue[0]);
                }}
                value={value}
                onBlur={onBlur}
              />
            )}
          />

          <p className="w-full text-center text-xl text-custom-green">
            {term || '?'} meses
          </p>
          {errors ? (
            <span className="mt-0.5 block px-3 text-xs text-error">
              *{errors.message}
            </span>
          ) : undefined}
        </div>
        <div className={'text-center ' + classNameResult}>
          <div className="rounded-3xl bg-white p-4 shadow-light-active dark:bg-custom-blue dark:shadow-dark-active">
            <p>
              <b>Cuota fija mensual</b>
            </p>
            <p className="whitespace-nowrap text-3xl text-custom-green">
              <b>{installment > 0 ? formatCurrency(installment) : '???'}</b>
            </p>
            <p className="text-xs">
              <b>*Valor aproximado</b>
            </p>
          </div>
          {/* <p className='mt-2 text-xs'>
						*¿Cómo son los pagos mensuales?{' '}
						<button className='hover:underline' onClick={() => showVideo('')}>
							Ver video: <Emoji symbol='video-camera' className='inline h-4' />
						</button>
					</p> */}
        </div>
      </>
    );
  };
  return {
    TermSlider,
    calculate: fetchCreditTaxes,
    create: fetchCreditTaxesCreate,
  };
};

export default useTermSlider;
