import React, { useState, useEffect } from 'react';

function ComponenteConBotones({ universidadId, lineas, onSeleccionChange }) {
  const [seleccion, setSeleccion] = useState(null);
  const [seleccionId, setSeleccionId] = useState(null);
  const [seleccionIdPrev, setSeleccionIdPrev] = useState(null);
  const [seleccionPrevState, setSeleccionPrevState] = useState(null);
  const [showBottomMessageBorealUPB, setShowBottomMessageBorealUPB] = useState(false);
  const [showBottomMessageEstrellaUPB, setShowBottomMessageEstrellaUPB] = useState(false);

  const handleClick = (opcion, id) => {
    setSeleccion((prevState) => {
      const nuevaSeleccion = prevState === opcion ? null : opcion;
      // guardar el nombre del estado anterior
      setSeleccionPrevState(prevState);

      if (opcion) {
        onSeleccionChange(id);
      } else {
        onSeleccionChange(opcion);
      }
      if ((Number(id) === 16 && (universidadId === '6' || universidadId === '7' || universidadId === '8' || universidadId === '9'))) {
        setShowBottomMessageBorealUPB(true);
      } else {
        setShowBottomMessageBorealUPB(false);
      }
      if ((Number(id) === 1 && (universidadId === '6' || universidadId === '7' || universidadId === '8' || universidadId === '9'))) {
        setShowBottomMessageEstrellaUPB(true);
      } else {
        setShowBottomMessageEstrellaUPB(false);
      }
      return nuevaSeleccion;
    });

    // guardar el id actual
    setSeleccionId((idPrevState) => {
      const nuevaSeleccion = idPrevState === id ? null : id;
      // guardar el id anterior
      setSeleccionIdPrev(idPrevState);
      return nuevaSeleccion;
    });
  };

  // valida para que se oculte o no el boton seleccionar
  useEffect(() => {
    if (seleccionPrevState || seleccionIdPrev || seleccionId) {
      const divElementSelect = document.getElementById(seleccion + seleccionId);
      const divElementPrevSelect = document.getElementById(
        seleccionPrevState + seleccionIdPrev
      );

      // Si se encuentra el div, ocultarlo
      if (divElementSelect) {
        divElementSelect.style.display = 'none';
      }
      // Si se encuentra el div, mostrarlo
      if (divElementPrevSelect) {
        divElementPrevSelect.style.display = 'block';
      }
    }
  }, [seleccionPrevState, seleccionIdPrev, seleccionId]);

  return (
    <div className="my-4 grid grid-cols-1 gap-4 gap-y-6 lg:my-6 lg:grid-cols-1 lg:gap-6">
      <div className="col-span-2 grid grid-cols-2 gap-8">
        {lineas.map((linea) => {
          if (linea.hide) {
            return null;
          }
          return (
            <div
              key={linea.id}
              className={`p-4 border-4 ${
                seleccion === linea.option
                  ? 'border-[10px] border-[#40e0d0]'
                  : 'border-gray-300'
              } rounded-lg`}
              onClick={() => {
                const newSeleccion =
                  seleccion === linea.option ? null : linea.option;
                handleClick(newSeleccion, linea.id);
              }}
            >
              <div className="flex flex-col items-center justify-center h-full">
                <h4 className="font-bold">{linea.title}</h4>
                <button onClick={() => handleClick(linea.option, linea.id)}>
                  <iframe
                    className="mx-auto"
                    width="100%"
                    height="auto"
                    src={linea.src}
                    title={linea.title}
                  ></iframe>
                </button>
                <div className="mt-8 mb-4">
                  <div id={linea.option + linea.id}>
                    <span
                      className="botonSpan relative rounded-full py-2  
                                            px-7 text-button disabled:opacity-50 bg-custom-purple 
                                            dark:bg-custom-green cursor-pointer col-start-2"
                    >
                      Seleccionar
                    </span>
                  </div>
                </div>
                {(Number(linea.id) === 16) && showBottomMessageBorealUPB && (
                  <div className="text-center">
                    <p>
                      Válida solo para órdenes de matrícula con más del 11% de descuento.
                    </p>
                  </div>
                )}
                {(Number(linea.id) === 1) && showBottomMessageEstrellaUPB && (
                  <div className="text-center">
                    <p>
                      Válida solo para órdenes de matrícula con máximo 10% de descuento.
                    </p>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ComponenteConBotones;
