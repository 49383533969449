import { ReactComponent as IdentityIcon } from '../../assets/images/identity/identity-pending.svg';
import { ReactComponent as IdentityCursoIcon } from '../../assets/images/identity/identity.svg';
import { ReactComponent as IdentityIconS } from '../../assets/images/identity/success-user.svg';
import React, { useState, useEffect } from 'react';
import { Cache } from 'aws-amplify';
import Loading from '../../components/shared/Loading';

const Deceval = () => {
  const [cacheCredit] = useState(Cache.getItem('requestCredit'));
  const [loading, setLoading] = useState(true);
  const [creditInfo, setCreditInfo] = useState([]);
  const fetchCreditData = async () => {
    setLoading(false);
    setCreditInfo([cacheCredit.estudiante]);
  };
  useEffect(() => {
    fetchCreditData();
  }, []);
  return loading ? (
    <Loading />
  ) : (
    <section>
      <h3 className="my-4 text-2xl font-bold">PAGARÉ: </h3>
      <div className="mt-4 grid grid-cols-1 divide-x-0 divide-y-2 lg:grid-cols-3 lg:divide-x-2 lg:divide-y-0">
        {creditInfo.map((req, index) => {
          return (
            <div
              key={index}
              className="flex flex-col space-y-6 px-14 py-4 text-center lg:py-0"
            >
              <>
                {/* eslint-disable-next-line multiline-ternary */}
                {req.status === 'pending' || req.status === 'link-opened' ? (
                  // Si req.status es 'pending'
                  <IdentityIcon className="h-20 w-auto flex-shrink-0 fill-current text-light transition duration-500 dark:text-dark" />
                ) : // eslint-disable-next-line multiline-ternary
                req.status === 'new' ? (
                  // Si req.status es 'new'
                  <IdentityCursoIcon className="h-20 w-auto flex-shrink-0 fill-current text-light transition duration-500 dark:text-dark" />
                ) : (
                  // Si req.status es otra cosa
                  <IdentityIconS className="h-20 w-auto flex-shrink-0 fill-current text-light transition duration-500 dark:text-dark" />
                )}
                <div>
                  <p>Estado de la firma:</p>
                  {req.status === 'pending' || req.status === 'link-opened' ? (
                    <p className="mb-4 text-center text-2xl font-bold text-red-500">
                      Pendiente
                    </p>
                  ) : req.status === 'new' ? (
                    <p className="mb-4 text-center text-2xl font-bold text-red-500">
                      Nuevo
                    </p>
                  ) : (
                    <p className="mb-4 text-center text-2xl font-bold text-red-500">
                      Exitosa
                    </p>
                  )}
                </div>
              </>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Deceval;
